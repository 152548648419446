.info-button-container {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }
  
  .info-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    color: #007bff;  /* Adjust this to match your background color */
    border: none;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .info-box {
    position: absolute;
    top: 40px;
    left: 0;
    width: 250px;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
  
  .info-box p {
    margin: 0 0 10px 0;
    font-size: 14px;
    color: #333;
  }