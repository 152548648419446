.honest-crm {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.section {
  flex: 1;
  padding: 20px 20px 20px 50px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.section h2 {
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  text-align: center;
  z-index: 10;
  position: relative;
}

.heaven {
  background: linear-gradient(to bottom, #87CEEB, #E0F6FF);
  position: relative;
  overflow: hidden;
}

.cloud {
  position: absolute;
  width: 100px;
  height: auto;
  opacity: 0.7;
  animation: floatCloud 60s linear infinite;
}

@keyframes floatCloud {
  0% {
    transform: translate(-100px, 0);
  }
  50% {
    transform: translate(calc(100vw + 100px), 20px);
  }
  100% {
    transform: translate(-100px, 0);
  }
}

.purgatory {
  background: linear-gradient(to bottom, #A9A9A9, #D3D3D3);
  filter: grayscale(50%);
}

.wasteland {
  background: linear-gradient(to bottom, #F4A460, #D2691E);
  position: relative;
}

.desert-item {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: auto;
}

.cactus {
  width: 80px;
}

.armadillo {
  width: 60px;
  animation: wander 30s linear infinite alternate;
}

@keyframes wander {
  0% { transform: translateX(0); }
  100% { transform: translateX(100px); }
}

.friend-face {
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 5;
}

.friend-face img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.friend-info {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 10;
  left: 45px;
  top: -10px;
  width: max-content;
  max-width: 180px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.friend-face:hover .friend-info {
  display: block;
}

.friend-info p {
  margin: 5px 0;
}

.friend-info p:first-child {
  font-weight: bold;
  color: #333;
}

.friend-info p:last-child {
  color: #666;
}

.add-friend-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 20;
}

.add-friend-form {
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(255,255,255,0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  z-index: 20;
}

.add-friend-form input,
.add-friend-form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.add-friend-form button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-friend-form button:hover {
  background-color: #45a049;
}

.statistics {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  z-index: 20;
}

.statistics h3 {
  margin-top: 0;
  color: #333;
}

.statistics ul {
  list-style-type: none;
  padding: 0;
}

.statistics li {
  margin-bottom: 5px;
  color: #666;
}

.day-scale {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.scale-mark {
  position: relative;
}

.scale-mark span {
  position: absolute;
  left: 35px;
  transform: translateY(-50%);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}