body {
    background-color: #008080; /* Teal background reminiscent of old interfaces */
    font-family: 'Arial', sans-serif;
    color: #ffffff;
  }
  
  .home-page {
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    background-color: #000080; /* Navy blue background */
    border: 2px solid #ffffff;
    padding: 20px;
    box-shadow: 5px 5px 0 #ffffff;
  }
  
  .home-page h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .home-page nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .home-page nav ul li {
    margin-bottom: 10px;
  }
  
  .home-page nav ul li a {
    display: block;
    background-color: #c0c0c0; /* Silver background for buttons */
    color: #000000;
    text-decoration: none;
    padding: 10px;
    text-align: center;
    border: 2px outset #ffffff;
    font-weight: bold;
  }
  
  .home-page nav ul li a:hover {
    background-color: #ffffff;
  }
  
  .home-page nav ul li a:active {
    border-style: inset;
  }
  
  /* Styles for the InfoButton */
  .info-button-container {
    position: fixed;
    top: 20px;
    right: 20px;
  }
  
  .info-button {
    background-color: #ffff00; /* Yellow background */
    color: #000000;
    border: 2px outset #ffffff;
    font-size: 18px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .info-box {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #ffffff;
    border: 2px solid #000080;
    padding: 10px;
    width: 200px;
    color: #000000;
    font-size: 14px;
  }