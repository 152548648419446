.floating-faces-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .add-yourself-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .add-yourself-button:hover {
    background-color: #333;
  }