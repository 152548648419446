body, html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .App {
    width: 100vw;
    height: 100vh;
    background-color: #f0f8ff; /* Light blue background */
  }