.mini-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .mini-screen h2 {
    margin-top: 0;
    color: #333;
  }
  
  .mini-screen img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .mini-screen p {
    color: #666;
    line-height: 1.5;
  }
  
  .contact-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .contact-button:hover {
    background-color: #45a049;
  }

  .learn-more-button {
    display: inline-block;
    background-color: #000000;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .learn-more-button:hover {
    background-color: #0056b3;
  }